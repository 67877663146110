<template>
  <main class="schedule-page-overflow ma-4">


    <!-- page header -->
    <div class="schedule-page-header-3 mx-2">

      <!-- page header: left -->
      <div class="all-center justify-space-around">
      </div>

      <!-- page header: center -->
      <div class="all-center">
        <date-header @on-date-change="onDateChange" :disable="copyMode" :value="currentDate"></date-header>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <!-- reports -->
        <div class="all-center" style="width: 33%">
          <report-dialog v-if="currentUserCanAllProjects" :reports="exportReports"></report-dialog>

          <v-btn v-if="canShowAllPrograms" @click="showAllPrograms = !showAllPrograms" :title="translations.showHidePrograms"
                 color="main" class="ml-4" small icon dark>
            <v-icon v-if="showAllPrograms">mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off-outline</v-icon>
          </v-btn>
        </div>

        <div class="all-center" style="width: 33%">
          <div v-if="!canReadOnly">
            <!-- copy from previous -->
            <v-btn v-if="copyMode" @click="exitFromCopy" small color="lightOrange">
              {{ translations.exitFromCopy }}
            </v-btn>

            <v-tooltip v-else-if="canEnterCopyMode" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="scheduleDialog = true" small color="white">
                  <v-icon class="mr-1">mdi-history</v-icon>
                </v-btn>
              </template>
              {{ translations.copyFromPreviousDay }}
            </v-tooltip>

            <!-- copy whole day -->
            <v-tooltip v-if="plannerCopyWholeDayIsEnabled && !copyMode && canEnterCopyMode && !canShowAllPrograms" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="showCloneDayProgramsDialog = true" color="white" class="ml-4" small>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              {{ translations.plannerCopyWholeDay }}
            </v-tooltip>
          </div>
        </div>

        <!-- new program -->
        <div class="all-center" style="width: 33%">
          <div v-if="!canReadOnly">
            <v-btn v-if="!disableSchedule && !error" @click="createNewSchedule" class="ml-1" color="main" dark small>
              <v-icon class="mr-1" small>mdi-plus</v-icon>
              {{ translations.project }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>


    <!-- main content -->
    <div class="schedule-page-content">
      <schedule-dialog v-model="scheduleDialog" @submit="val => onDateChange(val, true)"></schedule-dialog>

      <clone-schedule-dialog v-model="cloneScheduleDialog"
                             :start-value="currentDate"
                             @submit="val => cloneDraggedSchedule(val)">
      </clone-schedule-dialog>

      <clone-day-programs-dialog v-model="showCloneDayProgramsDialog" :loading="cloneProgramsLoading"
                                 @submit="cloneDayPrograms">
      </clone-day-programs-dialog>

      <!-- programs (schedules) -->
      <div class="storage-and-schedule-container">
        <!-- loading -->
        <div v-if="$apollo.queries.programs.loading" class="d-flex justify-center" style="height: 400px">
          <v-progress-circular :size="100" color="main" indeterminate></v-progress-circular>
        </div>

        <!-- schedules -->
        <div v-else-if="moreThanOneSchedule" :key="forceUpdate"
             @drop="dropOnSchedule">
          <draggable handle=".handle-drag-plan"
                     v-model="schedulesOnDate[currentDate]"
                     @start="drag = true"
                     @end="changeProjectOrder"
                     class="grid"
                     :disabled="canReadOnly">

            <transition-group tag="div" class="grid-schedule-section" name="grid-schedule-section"
                              @dragover="dragOver">
              <div v-for="clientSchedule in computedPrograms" :key="clientSchedule.id"
                   :draggable="isDraggableSchedule && clientSchedule.canAccess"
                   @dragover="dragOver"
                   @dragstart="dragSchedule(clientSchedule)"
                   @dragend="dragEndSchedule">
                <project-schedule :key="`${currentDate}-${clientSchedule.id}`"
                                  :availableCategoryProjects="availableCategoryProjects"
                                  :availableProjects="availableProjects"
                                  :clientSchedule="clientSchedule"
                                  :copyMode="copyMode"
                                  :currentDate="currentDate"
                                  :disable="disableSingleSchedule(clientSchedule)"
                                  :hasNextCategories="hasNextCategories"
                                  :hasNextProjects="hasNextProjects"
                                  :isLoadingCategories="$apollo.queries.categoryProjects.loading"
                                  :isLoadingProjects="$apollo.queries.projects.loading"
                                  :teamTypes="teamTypes"
                                  @delete-schedule="deleteSchedule"
                                  @on-change="onScheduleChange"
                                  @fetch-more-projects="projectsPage++"
                                  @fetch-more-categories="projectCategoriesPage++"
                                  @filter-projects="value => handleListFilter(value, 'projects')"
                                  @filter-project-categories="value => handleListFilter(value, 'categories')"
                                  style="width: 100%"
                                  :canReadOnly="canReadOnly">
                </project-schedule>
              </div>
            </transition-group>
          </draggable>
        </div>

        <!-- error -->
        <div v-else-if="error">
          <div class="mb-5" style="text-align: center">
            <h1>
              {{ translations.somethingWentWrong }}
            </h1>
            <p>{{ translations.pleaseCheckBackInMinutes }}</p>
          </div>
          <div class="d-flex justify-center">
            <v-img max-width="600" :src="require('../assets/bug.svg')"></v-img>
          </div>
        </div>

        <!-- empty -->
        <div v-if="!moreThanOneScheduleFiltered && !error && !canReadOnly" class="d-flex justify-center">
          <!-- empty in editable days -->
          <v-tooltip v-if="!disableSchedule" right>
            <template v-slot:activator="{ on }">
              <div v-on="on"
                   style="cursor: pointer; width: 100%; display: flex; justify-content: center;"
                   @click="createNewSchedule"
                   @drop="dropOnSchedule">
                <draggable handle=".handle-drag-plan"
                           class="grid">
                  <div :draggable="isDraggableSchedule"
                       @dragover="dragOver">
                    <v-img max-width="400px" :src="require('../assets/addSchedule.svg')"></v-img>
                  </div>
                </draggable>
              </div>
            </template>
            {{ translations.project }}
          </v-tooltip>

          <!-- empty in not editable day -->
          <template v-else>
            <div>
              <p>{{ translations.noSchedulesCreatedOnDate }}</p>
              <v-img max-width="420px" :src="require('../assets/noData.svg')" style="cursor:pointer"></v-img>
            </div>
          </template>
        </div>
      </div>

      <!-- activities boxes -->
      <div class="d-flex my-4" style="flex-wrap: wrap">
        <div v-for="item in unavailableEmployees" :key="`sick_bottom_${item.id}`">
          <member-card :item="item" style="border-left: 3px solid red; max-width: 300px; width: 210px"></member-card>
        </div>

        <div v-for="item in unavailableVehicles" :key="`vehicle_unavailable_${item.id}`">
          <vehicle-card :item="item" style="border-left: 3px solid darkorange; max-width: 300px; width: 210px"></vehicle-card>
        </div>
      </div>

      <!-- floating employees/vehicles/suppliers -->
      <v-menu v-if="!disableSchedule && !canReadOnly" :close-on-click="false" :close-on-content-click="false" nudge-left offset-y top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" color="main ma-3" dark fab small style="position: fixed; bottom: 0; right: 0">
            <v-icon>mdi-account-search</v-icon>
          </v-btn>
        </template>

        <v-card class="floating-resources-space">
          <div class="floating-resources-header">
            <div v-for="item in floatingResources" :key="item.id"
                 @click="tab = item.name"
                 class="floating-resources-item"
                 :class="tab === item.name ? 'selected' : ''">
              <v-icon class="mr-1" color="main" small>{{ item.icon }}</v-icon>
              {{ item.name }}
            </div>
          </div>

          <!-- employees-->
          <div v-show="tab === 'Addetti'" class="floating-resources-space">
            <list type="employee" :data="filterEmployees" @search="val => (employeeSearch = val)" @drag-item="data => dragItemFromList(data, 'employee')"></list>
          </div>

          <!-- vehicles-->
          <div v-show="tab === 'Attrezz.'" class="floating-resources-space">
            <list type="truck" :data="filterVehicles" @search="val => (vehicleSearch = val)" @drag-item="data => dragItemFromList(data, 'truck')"></list>
          </div>

          <!-- suppliers -->
          <div v-show="tab === 'Fornitori'" class="floating-resources-space">
            <v-card v-if="$apollo.queries.suppliers.loading">
              <v-card-title class="mb-0 pb-0">{{ translations.suppliersAvailable }}</v-card-title>
              <div style="margin: 0 auto; width: 93%">
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </div>
            </v-card>
            <list v-else type="supplier" :data="filterSuppliers" @search="val => (supplierSearch = val)" @drag-item="data => dragItemFromList(data, 'supplier')"></list>
          </div>
        </v-card>
      </v-menu>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import Schedule from '@/utils/constructors/Schedule'
import { createSchedulFromProgram } from '@/utils/constructors/scheduleBuilder'
import { eventBus, translations } from '@/utils/common'
import translationsUtil from '@/utils/common/translationsUtil'
import draggable from 'vuedraggable'

// GQL Query
import projectsQuery from '@/graphql/Projects.gql'
import categoryProjectsQuery from '@/graphql/CategoryProjects.gql'
import programQuery from '@/graphql/Program.gql'
import employeesAndStatusQuery from '@/graphql/EmployeesAndStatus.gql'
import vehiclesAndStatusQuery from '@/graphql/VehiclesAndStatus.gql'
import suppliersQuery from '@/graphql/Suppliers.gql'
import saveAndUpdateProgramQuery from '@/graphql/SaveAndUpdateProgram.gql'
import deleteProgramQuery from '@/graphql/DeleteProgram.gql'
import changeProgramsOrderMutation from '@/graphql/ChangeProgramsOrder.gql'
import cloneProgramMutation from '@/graphql/CloneProgram.gql'

// Service
import ProgramService from '@/service/program/ProgramService'

// Components
import MemberCard from '@/components/MemberCard.vue'
import VehicleCard from '@/components/VehicleCard.vue'
import ProjectSchedule from '@/components/ProjectSchedule.vue'
import DateHeader from '@/components/DateHeader.vue'
import ScheduleDialog from '../components/ScheduleDialog.vue'
import CloneScheduleDialog from '../components/CloneScheduleDialog.vue'
import ReportDialog from '@/components/Report/ReportDialog.vue'
import List from '@/components/List.vue'
import CloneDayProgramsDialog from '@/components/CloneDayProgramsDialog'
import {
  getDraggableSchedule,
  getPlannerEyeIsEnabled,
  getTeamTypes,
  getPlannerCopyWholeDayIsEnabled
} from '@/utils/api/config'

export default {
  name: 'Schedule',

  components: {
    MemberCard,
    ProjectSchedule,
    List,
    DateHeader,
    ScheduleDialog,
    CloneScheduleDialog,
    VehicleCard,
    draggable,
    ReportDialog,
    CloneDayProgramsDialog
  },

  apollo: {
    projects: {
      query: projectsQuery,
      variables() {
        return {
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: this.pageSize,
          page: this.projectsPage,
          filter: this.projectsFilter
        }
      },
      skip() {
        return !this.currentDate
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.projects
        this.availableProjects.push(...content)
        this.hasNextProjects = hasNext
      }
    },

    categoryProjects: {
      query: categoryProjectsQuery,
      variables() {
        return {
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
          fetchNonCategory: false,
          fetchCategory: true,
          activeStatus: true,
          pageSize: this.pageSize,
          page: this.projectCategoriesPage,
          filter: this.categoryProjectsFilter
        }
      },
      skip() {
        return !this.currentDate
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.categoryProjects
        this.availableCategoryProjects.push(...content)
        this.hasNextCategories = hasNext
      }
    },

    // fetch programs
    programs: {
      query: programQuery,
      variables() {
        return {
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null
        }
      },
      skip() {
        return !this.currentDate
      },
      error(error) {
        this.error = error
      },
      fetchPolicy: 'network-only'
    },

    // Fetch employee statuses
    employeesAndStatus: {
      query: employeesAndStatusQuery,
      variables() {
        return {
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null
        }
      },
      skip() {
        return !this.currentDate
      },
    },

    // Fetch vehicles and statuses
    vehiclesAndStatus: {
      query: vehiclesAndStatusQuery,
      variables() {
        return {
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null
        }
      },
      skip() {
        return !this.currentDate
      },
    },

    // Fetch Suppliers
    suppliers: {
      query: suppliersQuery,
      fetchPolicy: 'network-only'
    }
  },

  /**
   * These watchers use the same name as the gql queries. They are mainly used to manipulate the data
   * in order to structure it to the components.
   */

  watch: {
    async currentDate(val) {
      if (!this.schedulesOnDate[val]) {
        this.error = ''
      }
    },

    /**
     * Function called to initialize multiple ProjectSchedule (aka Program) components with today's programs
     * The programs() watcher function is using the programs.gql data from apollo.
     * The data is separated into 2 schedules. One is the main schedule where employees, suppliers, and vehicles
     * are assign a time and note. The other schedule is built the same way, but it is for the notes for the user.
     */
    async programs() {
      if (this.programs.length > 0) {
        const programSchedule = []
        this.programs.forEach(prog => {
          const project = {
            id: prog.id,
            names: prog?.programProjects?.map(({ project }) => project.keyCode) || [],
            client: prog.programProjects.map(({ project }) => project.clientCode) || [],
            conGlomerateDesc: prog.asphaltConcrete || '',
            quintalsExpectedDesc: prog.quantity || '',
            part: prog.programProjects.map(a => a?.part || [])?.[0],
            projects: prog?.programProjects?.map(({ id, project, part, main, orderNumber, allowance, supervisor, quotationNumber }) => ({
              id: id,
              main: main,
              projectId: project.id,
              name: project.keyCode,
              label: project.label,
              part: part?.id,
              parts: project?.parts,
              projectFilter: project.keyCode,
              projectSearchLoader: false,
              orderNumber: orderNumber,
              allowance: allowance,
              supervisor: supervisor,
              quotationNumber: quotationNumber
            }))
          }
          const teams = []
          prog.teams.forEach(t => {
            teams.push({
              id: t.id,
              ora: t.time.length === 4 ? `0${t.time}` : t.time,
              trucks: t.vehicles.map(a => ({
                id: a.id,
                code: a.code || '',
                label: a.label || '',
                type: 'truck'
              })),
              people: [
                ...(t.employees?.map(e => ({ ...e, type: 'employee' })) || []),
                ...(t.suppliers?.map(s => ({
                  ...s,
                  name: s.description,
                  type: 'supplier'
                })) || [])
              ],
              note: t.note,
              orderNumber: t.orderNumber,
              foremanEmployee: t.foremanEmployee,
              teamType: t.type
            })
          })

          const schedule = new Schedule(prog.id, prog.orderNumber, project, teams, prog.canAccess)
          programSchedule.push(schedule)
        })

        // $set is used here because the state in vue does not update on a dynamic object
        this.$set(this.schedulesOnDate, this.currentDate, programSchedule)
      } else {
        this.$set(this.schedulesOnDate, this.currentDate, [])
        this.changeEmployeeIdUsed([])
        this.changeVehicleIdUsed([])
      }

      // getIdsUsed sets the store and localstorage
      await this.getIdsUsed()
    },

    employeesAndStatus() {
      this.availableEmployees = this.employeesAndStatus
        .filter(({ employeeStatus }) => !employeeStatus)
        .map(({ employees }) => employees)
        .flat()

      this.unavailableEmployees = this.employeesAndStatus
        .filter(({ employeeStatus }) => employeeStatus)
        .map(({ employeeStatus, employeeActivities }) => ({
          id: employeeStatus.keyCode,
          title: employeeStatus.description,
          members: employeeActivities
        }))
    },

    vehiclesAndStatus() {
      let available = []
      let unavailable = []
      this.vehiclesAndStatus.forEach(vs => {
        if (!vs?.type) {
          available.push(...vs.vehicles)
        } else if (!this.$store.getters.vehicleOperations.find(vo => vo.value === vs.type).requestable) {
          available.push(...vs.vehicleActivities.map(va => va.vehicle))
        } else {
          unavailable.push({
            id: vs.type,
            title: translationsUtil('vehicleOperation_' + vs.type),
            vehicleActivities: vs.vehicleActivities
          })
        }
      })

      this.availableVehicles = available.sort((a, b) => a.code.localeCompare(b.code))
      this.unavailableVehicles = unavailable
    }
  },

  data: () => ({
    currentDate: null,
    currentDateTime: 0,

    employeeSearch: '',
    vehicleSearch: '',
    supplierSearch: '',

    tab: 'Addetti',

    availableEmployees: [],
    unavailableEmployees: [],

    availableVehicles: [],
    unavailableVehicles: [],

    schedulesOnDate: {},

    scheduleDialog: false,
    showCloneDayProgramsDialog: false,
    cloneProgramsLoading: false,
    cloneScheduleDialog: false,
    deleteProgramLoader: false,
    error: '',
    forceUpdate: 0,
    copyMode: false,
    dontClearDates: false,
    projectsPage: 1,
    projectCategoriesPage: 1,
    pageSize: 10,
    hasNextProjects: true,
    hasNextCategories: true,
    availableProjects: [],
    availableCategoryProjects: [],
    projectsFilter: '',
    categoryProjectsFilter: '',
    floatingResources: [
      {
        id: 1,
        name: 'Addetti',
        icon: 'mdi-account-hard-hat'
      },
      {
        id: 2,
        name: 'Attrezz.',
        icon: 'mdi-tow-truck'
      },
      {
        id: 3,
        name: 'Fornitori',
        icon: 'mdi-account-arrow-right'
      }
    ],
    showAllPrograms: false
  }),

  created() {
    this.init()

    // This function is called when the user closes their window
    // when the user closes their window we clear out the localStorage
    window.onbeforeunload = e => {
      e.preventDefault()
      if (!this.dontClearDates) {
        localStorage.removeItem('dateToCompare')
      }
    }

    // Listener for when localStorage changes
    window.onstorage = ({ newValue, oldValue, key }) => {
      if (key === 'dateToCompare') {
        if (!newValue) {
          const parseOldValue = JSON.parse(oldValue)
          if (this.currentDate === parseOldValue.dateCopy) {
            window.close()
            return
          }
        }
      }

      if (key === 'forceCloseOrigCopyModeWindow') {
        const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
        if (this.currentDate === dateToCompare.dateCopy) {
          window.close()
          return
        }
      }

      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      // if the date of copy is = to the date that the user is currently on then we update the store to use the localStorage values
      if (dateToCompare?.dateCopy === this.currentDate) {
        this.changeEmployeeIdUsed(dateToCompare.employeeIds)
        this.changeSupplierIdUsed(dateToCompare.supplierIds)
        this.changeVehicleIdUsed(dateToCompare.vehicleIds)
        this.copyMode = true
        this.forceUpdate++
        eventBus.sideNavDisabler(true)
        return
      } else if (dateToCompare?.date === this.currentDate) {
        return
      }

      // If the currentDate that the user is on greater than todays date
      // we make sure to empty the vuex state
      if (!(this.currentDate >= this.todaysDate)) {
        this.changeEmployeeIdUsed([])
        this.changeSupplierIdUsed([])
        this.changeVehicleIdUsed([])
      }

      this.copyMode = false
      eventBus.sideNavDisabler(false)
    }

    const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
    this.copyMode = dateToCompare ? true : false
  },

  mixins: [MomentMixin],

  methods: {
    ...mapActions([
      'removeEmployeeIdUsed',
      'changeEmployeeIdUsed',
      'changeCurrentDate',
      'changeCurrentDateTime',
      'changeSupplierIdUsed',
      'changeVehicleIdUsed',
      'removeEmployeeIdUsedMultiple',
      'removeSupplierIdUsedMultiple',
      'removeVehicleIdUsedMultiple',
      'insertIntoEmployeeIdUsed',
      'insertIntoVehicleIdUsed',
      'getDraggedSchedule',
      'changeDraggedSchedule',
      'removeDraggedSchedule'
    ]),

    init() {
      if (this.$route.query.date) {
        this.scheduleDialog = false
        const dateParse = this.$route.query.date.split('-')
        if (dateParse.length === 3) {
          const [month, day, year] = dateParse
          if (Number(month) && Number(day) && Number(year) && Number(month) <= 12 && Number(day) <= 31) {
            this.currentDate = `${year}-${month}-${day}`
            this.currentDateTime = this.momentToDateGetTime(this.$route.query.date)
          } else {
            this.currentDate = this.getSimpleDay().substr(0, 10)
            this.currentDateTime = moment(new Date().setHours(0, 0, 0, 0))
              .toDate()
              .getTime()
          }
        } else {
          this.currentDate = this.getSimpleDay().substr(0, 10)
          this.currentDateTime = moment(new Date().setHours(0, 0, 0, 0))
            .toDate()
            .getTime()
        }
      } else {
        this.currentDate = this.getSimpleDay().substr(0, 10)
        this.currentDateTime = moment(new Date().setHours(0, 0, 0, 0))
          .toDate()
          .getTime()
      }

      this.changeCurrentDate(this.currentDate)
      this.changeCurrentDateTime(this.currentDateTime)
      this.$set(this.schedulesOnDate, this.currentDate, [])
    },

    exitFromCopy() {
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      if (dateToCompare?.dateCopy === this.currentDate) {
        this.changeEmployeeIdUsed([])
        this.changeSupplierIdUsed([])
        this.changeVehicleIdUsed([])
      }
      localStorage.removeItem('dateToCompare')
      this.copyMode = false
      eventBus.sideNavDisabler(false)
      if (dateToCompare?.dateCopy === this.currentDate) {
        window.close()
      }
    },

    onScheduleChange(schedule) {
      const index = this.schedulesOnDate[this.currentDate].findIndex(({ id }) => id === schedule.id)
      this.schedulesOnDate[this.currentDate][index] = schedule
    },

    getProgramOrderNumber() {
      const orderNumsArray = this.schedulesOnDate[this.currentDate].map(schedule => schedule.orderNumber)
      return Math.max.apply(null, orderNumsArray) + 1
    },

    async saveOrUpdateProgram({
                                id = null,
                                asphaltConcrete = '',
                                quantity = '',
                                orderNumber = 0,
                                programProjects = null,
                                teams = null,
                                canAccess = true
                              }) {
      if (this.schedulesOnDate[this.currentDate].length > 0) {
        orderNumber = this.getProgramOrderNumber()
      }

      const program = {
        id: id,
        asphaltConcrete: asphaltConcrete,
        quantity: quantity,
        dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
        programProjects: programProjects,
        orderNumber: orderNumber,
        teams: teams,
        canAccess: canAccess
      }

      if (!id) {
        delete program.id
        delete program.programProjects
        delete program.teams
      }

      const { data } = await this.$apollo
        .mutate({
          mutation: saveAndUpdateProgramQuery,
          variables: { program }
        })
        .catch(error => {
          this.handleError(error)
        })

      return data.saveProgram
    },

    onDateChange({ date, dateTime }, refresh) {
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      this.resetProjectList()
      this.currentDate = date
      this.changeCurrentDate(date)
      this.changeCurrentDateTime(dateTime)
      const [year, month, day] = date.split('-')
      const formattedDate = `${month}-${day}-${year}`
      if (refresh && dateToCompare.dateCopy === this.currentDate) {
        this.dontClearDates = true
        this.$router.push({ name: 'Schedule', query: { date: formattedDate } })
        return this.$router.go({
          name: 'Schedule',
          query: { date: formattedDate }
        })
      }
      this.$router.push({ name: 'Schedule', query: { date: formattedDate } })
    },

    // This function checks if the schedule is being copied to another schedule.
    // after that it then grab all the employee Ids and vehicle Ids and set the localStorage & vuex store
    async getIdsUsed() {
      if (this.copyMode) {
        const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))

        if (dateToCompare?.dateToCopy === this.currentDate) return
      }

      const { data: employeesAndStatusByDate } = await this.$apollo.query({
        query: employeesAndStatusQuery,
        variables: {
          dateStr: this.formatDate(this.currentDate)
        }
      })

      const { data: vehiclesAndStatusByDate } = await this.$apollo.query({
        query: vehiclesAndStatusQuery,
        variables: {
          dateStr: this.formatDate(this.currentDate)
        }
      })
      const teams = this.schedulesOnDate[this.currentDate].map(({ teams }) => teams).flat()

      // Get Employees, Suppliers, and Vehicles in main schedule
      const people = teams.map(t => t.people).flat()
      const vehicles = teams.map(t => t.trucks).flat()
      const vehicleIds = vehicles.map(({ id }) => id)
      const employeeIds = people.filter(({ type }) => type === 'employee').map(({ id }) => id)

      // Get Employees that are listed as sick or on holidays
      const unavailableEmployeeIds = employeesAndStatusByDate.employeesAndStatus
        .filter(({ employeeStatus }) => employeeStatus && !employeeStatus?.dayPart)
        .map(({ employeeActivities }) => employeeActivities)
        .flat()
        .map(({ employee }) => employee.id)

      // Get Vehicles that are in repair
      const unavailableVehicleIds = vehiclesAndStatusByDate.vehiclesAndStatus
        .filter(({ type }) => type)
        .map(({ vehicleActivities }) => vehicleActivities)
        .flat()
        .map(({ vehicle }) => vehicle.id)

      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))

      // We check if the date that is being compared is the current schedule
      if (dateToCompare?.date === this.currentDate) {
        const stringify = JSON.stringify({
          ...dateToCompare,
          employeeIds: [...employeeIds, ...unavailableEmployeeIds],
          // supplierIds,
          vehicleIds: [...vehicleIds, ...unavailableVehicleIds]
        })
        // Then set in localStorage the employee and vehicle ids that will be compared
        localStorage.setItem('dateToCompare', stringify)
      }

      if (dateToCompare?.dateCopy === this.currentDate) return

      // Here if the dateCopy is not the current date then we manipulate the vuex store to makes sure it gets the changes
      this.changeEmployeeIdUsed([...employeeIds, ...unavailableEmployeeIds])
      // this.changeSupplierIdUsed(supplierIds);
      this.changeVehicleIdUsed([...vehicleIds, ...unavailableVehicleIds])
    },

    // Delete Program/Schedule. In this function we have to make sure we remove all the trucks and employee ids
    // in localStorage and the vuex store so that the view updates and will no longer disable the chips.
    async deleteSchedule(scheduleId) {
      const index = this.schedulesOnDate[this.currentDate].findIndex(({ id }) => id === scheduleId)
      const { teams } = this.schedulesOnDate[this.currentDate][index]
      const people = teams.map(({ people }) => people).flat()
      const empIds = []
      const supIds = []
      const vehicleIds = teams
        .map(({ trucks }) => trucks)
        .flat()
        .map(({ id }) => id)
      people.forEach(({ type, id }) => {
        if (type === 'employee') empIds.push(id)
        else if (type === 'supplier') supIds.push(id)
      })

      this.removeEmployeeIdUsedMultiple(empIds)
      this.removeSupplierIdUsedMultiple(supIds)
      this.removeVehicleIdUsedMultiple(vehicleIds)
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      if (dateToCompare?.date === this.currentDate) {
        dateToCompare.vehicleIds = this.vehicleIdUsed
        dateToCompare.supplierIds = this.supplierIdUsed
        dateToCompare.employeeIds = this.employeeIdUsed
        localStorage.setItem('dateToCompare', JSON.stringify(dateToCompare))
      }

      this.deleteProgramLoader = true
      await this.$apollo
        .mutate({
          mutation: deleteProgramQuery,
          variables: { programId: scheduleId }
        })
        .catch(error => {
          this.handleError(error)
        })
      this.deleteProgramLoader = false

      this.schedulesOnDate[this.currentDate].splice(index, 1)
    },

    // This function enables when the item is being dragged
    dragItemFromList(item, type) {
      // create a copy of the item
      const draggedItem = Object.assign({}, item)
      draggedItem.type = type

      // set the item that is being dragged into local storage
      localStorage.setItem('dragged-item-list', JSON.stringify(draggedItem))
    },

    dragOver(e) {
      e.preventDefault()
    },

    dragEnter() {
    },

    dragSchedule(schedule) {
      if (this.isDraggableSchedule) {
        const draggedItemList = localStorage.getItem('dragged-item-list')
        const draggedItemRow = localStorage.getItem('dragged-item-row')
        if (draggedItemList === null && draggedItemRow === null) {
          schedule.originalDate = this.currentDate
          this.changeDraggedSchedule(schedule)
        }
      }
    },

    dragEndSchedule() {
      this.removeDraggedSchedule()
    },

    async dropOnSchedule() {
      // if drag entire program is enabled
      if (this.isDraggableSchedule) {
        // if the element dragged is not a single item or an entire row (and if the program to be dragged is present)
        const draggedItemList = localStorage.getItem('dragged-item-list')
        const draggedItemRow = localStorage.getItem('dragged-item-row')
        const draggedSchedule = await this.getDraggedSchedule()
        if (draggedItemList === null && draggedItemRow === null && draggedSchedule && draggedSchedule.originalDate < this.currentDate) {
          this.cloneScheduleDialog = true
        }
      }
    },

    async createNewSchedule() {
      const newProgram = await this.saveOrUpdateProgram({ id: null })
      const newSchedule = new Schedule(newProgram.id, newProgram.orderNumber, null, null, true)

      this.addScheduleOnCurrentDate(newSchedule)
    },

    async cloneDraggedSchedule({ cloneDatesRange }) {
      const { id: programId } = await this.getDraggedSchedule()
      const orderNumber = this.schedulesOnDate[this.currentDate].length === 0 ? 0 : this.getProgramOrderNumber()

      const {
        data: { cloneProgram: clonedPrograms }
      } = await this.$apollo
        .mutate({
          mutation: cloneProgramMutation,
          variables: { programId, cloneDatesRange, orderNumber }
        })
        .catch(error => {
          this.handleError(error)
        })

      // filter the programs which have been cloned on the current date
      // than map the program objects to schedules
      // finally for each schedule add it to the list
      clonedPrograms
        .filter(({ dateStr }) => this.formatDate(this.currentDate) === dateStr)
        .map(program => createSchedulFromProgram(program))
        .forEach(schedule => this.addScheduleOnCurrentDate(schedule))

      // set the ids used state
      await this.getIdsUsed()

      this.removeDraggedSchedule()
    },

    async cloneDayPrograms(dates) {
      this.cloneProgramsLoading = true
      try {

        await ProgramService.clonePrograms(dates)
        const [year, month, day] = dates.cloneToDate.split('-')
        const formattedDate = `${month}-${day}-${year}`

        this.$router.push({ name: 'Schedule', query: { date: formattedDate } })
        return this.$router.go({
          name: 'Schedule',
          query: { date: formattedDate }
        })
      } catch (e) {
        console.log(e)
      }

      this.cloneProgramsLoading = false
    },

    addScheduleOnCurrentDate(schedule) {
      this.schedulesOnDate[this.currentDate].push({
        ...schedule.getSchedule(),
        id: schedule.id
      })
    },

    async changeProjectOrder() {
      await this.$apollo
        .mutate({
          mutation: changeProgramsOrderMutation,
          variables: {
            programIds: this.schedulesOnDate[this.currentDate].map(({ id }) => id)
          }
        })
        .catch(error => {
          this.handleError(error)
        })
    },

    handleListFilter(value, type) {
      if (type === 'projects') {
        this.availableProjects = []
        this.projectsPage = 1
        this.projectsFilter = value
      } else if (type === 'categories') {
        this.availableCategoryProjects = []
        this.projectCategoriesPage = 1
        this.categoryProjectsFilter = value
      }
    },

    isMobileScreen() {
      return window.matchMedia('only screen and (max-width: 600px)').matches
    },

    disableSingleSchedule(clientSchedule) {
      return !clientSchedule.canAccess || this.disableSchedule
    },

    resetProjectList() {
      this.availableProjects = []
      this.projectsPage = 1
      this.availableCategoryProjects = []
      this.projectCategoriesPage = 1
      this.projectsFilter = ''
      this.categoryProjectsFilter = ''
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo,
      todaysDate: state => state.date.todaysDate,
      todaysDateTime: state => state.date.todaysDateTime,
      dateTime: state => state.date.currentDateTime,
      employeeIdUsed: state => state.employees.employeeIdUsed,
      supplierIdUsed: state => state.suppliers.supplierIdUsed,
      vehicleIdUsed: state => state.vehicles.vehicleIdUsed,
      currentUserCanAllProjects: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'All Projects'),
      canReadOnly: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Read-only')
    }),

    translations: () => translations,

    disableSchedule() {
      if (this.copyMode) {
        const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
        if (dateToCompare.dateCopy === this.todaysDate && this.todaysDate === this.currentDate) {
          return true
        }
      }

      return this.todaysDate > this.currentDate || this.isMobileScreen()
    },

    moreThanOneSchedule() {
      return this.schedulesOnDate?.[this.currentDate]?.length > 0
    },

    moreThanOneScheduleFiltered() {
      return this.schedulesOnDate[this.currentDate] && this.schedulesOnDate[this.currentDate].length > 0 && this.computedPrograms.length > 0
    },

    filterEmployees() {
      return this.availableEmployees.filter(emp => emp.name?.toLowerCase().indexOf(this.employeeSearch.toLowerCase()) > -1)
    },

    filterVehicles() {
      return this.availableVehicles.filter(v => `${v.code} - ${v.label}`?.toLowerCase().indexOf(this.vehicleSearch.toLowerCase()) > -1).map(v => ({
        ...v,
        name: `${v.code} - ${v.label} `
      }))
    },

    filterSuppliers() {
      if (!this.suppliers) return []
      return this.suppliers
        .filter(s => s.description.toLowerCase().indexOf(this.supplierSearch.toLowerCase()) > -1)
        .map(a => ({
          ...a,
          name: a.description
        }))
    },

    teamTypes() {
      return getTeamTypes()
    },

    isDraggableSchedule() {
      return this.copyMode && getDraggableSchedule()
    },

    plannerCopyWholeDayIsEnabled() {
      return getPlannerCopyWholeDayIsEnabled()
    },

    canEnterCopyMode() {
      return !this.isMobileScreen()
    },

    exportReports() {
      return [
        {
          name: 'Plan',
          icon: 'mdi-table-account',
          oneDate: true,
          modeAvailable: ['pdf'],
          params: {
            accountId: this.userInfo?.account?.id
          }
        },
        {
          name: 'planPlainReport',
          icon: 'mdi-text-account',
          oneDate: true
        },
        {
          name: 'PlanningEmployee',
          icon: 'mdi-account-hard-hat',
          params: {
            accountId: this.userInfo?.account?.id
          }
        },
        {
          name: 'EventStore',
          icon: 'mdi-chart-gantt',
          params: {
            sector: 'Planner'
          }
        }
      ]
    },

    canShowAllPrograms() {
      return getPlannerEyeIsEnabled() && !this.userInfo?.account?.capabilities?.some(c => c.name?.includes('All Projects'))
    },

    computedPrograms() {
      if (this.showAllPrograms) {
        return this.schedulesOnDate[this.currentDate]
      } else {
        return this.schedulesOnDate[this.currentDate].filter(p => p.canAccess)
      }
    }
  }
}
</script>

<style scoped>
.schedule-page-overflow {
  overflow: hidden;
}

.schedule-page-header-3 {
  height: 40px;

  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}

.schedule-page-header-3 > * {
  width: 33%;
}

.schedule-page-content {
  height: calc(100vh - 40px - 20px - 16px - 16px);
  overflow-y: scroll;
}

.grid-schedule-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, 445px);
  grid-gap: 10px;
}

.floating-resources-space {
  width: 275px;
}

.floating-resources-header {
  display: grid;
  grid-template-columns: repeat(auto-fill, 33.3%);
  border-top: 4px solid #104272;
}

.floating-resources-item {
  display: flex;
  justify-content: center;
  border-right: 1px solid #ddd;
  background-color: #d0cfcf;
  padding: 5px;
}

.floating-resources-item.selected {
  background-color: #ffffff;
}

.floating-resources-item:last-child {
  border-right: none;
}

.floating-resources-item:hover {
  cursor: pointer;
  background-color: #9e9e9e;
}

.hover {
  cursor: pointer;
}

.member-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  /* used to check screen size */
}

@media screen and (min-width: 750px) {
  .grid-schedule-section {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .member-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1010px) {
  .grid-schedule-section {
    grid-template-columns: repeat(auto-fill, 445px);
    row-gap: 90px;
    column-gap: 10px;
  }

  .member-list {
    grid-template-columns: repeat(8, 1fr);
  }

  .member {
    max-width: 300px;
    width: 210px;
  }
}
</style>
